import * as React from "react"
import { Row, Col } from "react-bootstrap"
import CustomLink from "../CustomLink"
import Divider from "../Divider"
import CustomButton from "../CustomButton"
// Styles
import "./ContactCard.scss"

import {ImageModule} from '../../modules/Image_Module'

const ContactCard = ( props ) => {

    var contact_info = [];
    if(props.main_building_manager){
        contact_info.push(props.main_building_manager)
    }
    if(props?.assistant_building_managers?.length > 0){
        props?.assistant_building_managers?.map(item => contact_info.push(item))
    }

    console.log("contact_info", contact_info);


    return (
        <>
        { contact_info?.length > 0 && contact_info.map((item, index) => {
            return(
                <div className={`contact-card-wrappper`}>
                    <div className="contact-image">
                        <ImageModule ImageSrc={item?.picture} altText={item.name}/>
                    </div>
                    <div className="contact-details">
                        <span>
                            <p className="contact-name">{item?.name || "Rachel Carrington"} </p>
                            <p className="designation">{item?.designation || "Lettings Expert"}</p>
                        </span>
                        <span className="contact-us">
                            <p className="contact-number">
                                <a href={`tel:${item?.mobile}`} className={ `custom-link without-caret`}>{item?.mobile || "+44 7891 219761"}</a>
                            </p>
                            <Divider delimiter="|" />
                            <a href={`mailto:${item?.email_address}`} className={ `custom-link without-caret`}>
                                Contact us
                            </a>
                        </span>

                    </div>
                </div> 
            )
             })       
         } 
        </>
    )
}

export default ContactCard